
import { DocComponents } from "@/network/officialDoc.ts";
import Detail from "./detail.vue";
import FundAmountReviewForm from "./fundAmountReviewForm.vue";
import FundAmountReviewFormView from "./fundAmountReviewFormView.vue";
import { reactiveShowSetting } from "@/types/showSetting.ts";
import ShowRelBtn from "@/components/showRelBtn.vue";
import { useRouter, useRoute } from "vue-router";
import roles from "@/types/roles.ts";
import Base from "@/views/layout/Base.vue";
import Pager from "@/views/layout/pager.vue";

import { useQueryAmountReview, useAction } from "@/network/projectFundApply.ts";
import {
  defineComponent,
  inject,
  onMounted,
  onUpdated,
  reactive,
  ref,
} from "vue";
import { ElMessageBox } from "element-plus";
export default defineComponent({
  components: {
    ...DocComponents,
    Detail,
    ShowRelBtn,
    FundAmountReviewForm,
    FundAmountReviewFormView,
    Base,
    Pager,
  },
  setup() {
    const fundReviewFormId = ref(null);
    const fundReviewViewId = ref(null);
    const fundReviewViewSetting = reactive({
      id: fundReviewViewId,
      visible: false,
      project_Name: "",
      project_Code: "",
    });
    const fundReviewFormSetting = reactive({
      id: fundReviewFormId,
      visible: false,
      project_Name: "",
      project_Code: "",
    });

    const detailSetting = reactiveShowSetting({ review: false });
    const docShowSetting = reactiveShowSetting();
    const user: any = inject("user");

    const showForm = (item) => {
      fundReviewFormSetting.visible = true;
      fundReviewFormSetting.project_Name = item.project_Name;
      fundReviewFormSetting.project_Code = item.project_Code;
      fundReviewFormId.value = item.id;
    };

    const showViewForm = (item) => {
      fundReviewViewSetting.visible = true;
      fundReviewViewSetting.project_Name = item.project_Name;
      fundReviewViewSetting.project_Code = item.project_Code;
      fundReviewViewId.value = item.id;
    };

    const showDocContent = (id) => {
      docShowSetting.id = id;
      docShowSetting.visible = true;
    };

    // const backConfirm = (id) => {
    //   ElMessageBox.prompt("请填写退回理由", "退回", {
    //     type: "warning",
    //     customClass: "backConfirmForm",
    //     confirmButtonText: "退回",
    //     cancelButtonText: "取消",
    //     inputType: "textarea",
    //     inputPattern: /(.+){5,}/,
    //     inputErrorMessage: "理由不得少于5个字！",
    //     inputPlaceholder: "请输入理由",
    //   }).then(({ value }) => {
    //     run(id, { forceFlag: 1, memo: value });
    //   });
    // };

    const [isLoading, result, query, filter, pager] = useQueryAmountReview();
    const [isProcessing, back] = useAction();
    onMounted(() => {
      query();
    });
    return {
      isProcessing,
      back,
      showDocContent,
      showViewForm,
      query,
      showForm,
      docShowSetting,
      detailSetting,
      isLoading,
      roles,
      user,
      pager,
      data: result,
      filter,
      fundReviewViewSetting,
      fundReviewFormSetting,
      fundReviewFormId,
      fundReviewViewId,
    };
  },
});
