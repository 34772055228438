<template>
  <Base titleIcon="el-icon-s-claim">
  <DocContent :setting="docShowSetting" v-if="docShowSetting.visible"></DocContent>

  <el-table :data="data.list" v-loading="isLoading">
    <el-table-column label="地区" prop="areaName"></el-table-column>
    <el-table-column label="项目编号" prop="project_Code"></el-table-column>
    <el-table-column label="项目名称" prop="project_Name" width="400">
      <template #default="{ row: item }">
        <ShowRelBtn :id="item.projectId" :label="item.project_Name" :showSetting="detailSetting" @link="
          detailSetting.id = item.projectId;
        detailSetting.visible = true;
        detailSetting.review = false;
        "></ShowRelBtn>
      </template>
    </el-table-column>
    <!-- <el-table-column label="地区" prop="areaName"></el-table-column> -->
    <el-table-column label="文保单位" prop="heritageUnit_Name">
      <template #default="{ row: item }">
        <a href="javascript:void()"> {{ item.heritageUnit_Name }}</a>
      </template>
    </el-table-column>
    <el-table-column label="级别" prop="heritageUnit_LevelName">
    </el-table-column>
    <el-table-column label="批文" width="200" >
      <template #default="{ row: item }">
        <template v-if="item.setupDoc_Id != null">
          计划书：<DocViewBtn :doc="{
            id: item.setupDoc_Id,
            doc_Compile_No: item.setupDoc_Name,
          }" @show="showDocContent(item.setupDoc_Id)"></DocViewBtn>
        </template>
        <template v-if="item.proposalDoc_Id != null">
          方案：<DocViewBtn :doc="{
            id: item.proposalDoc_Id,
            doc_Compile_No: item.proposalDoc_Name,
          }" @show="showDocContent(item.proposalDoc_Id)"></DocViewBtn>
        </template>
        <template v-else>
          <i class="el-icon-warning Red"></i> {{ item.noDoc_Note }}
        </template>
      </template>
    </el-table-column>
    <el-table-column label="项目总概算（万元）" prop="budget_Total"></el-table-column>
    <el-table-column label="评审金额（万元）" prop="assessment_Money"></el-table-column>
    <el-table-column label="接收日期" prop="recvDate"></el-table-column>
    <el-table-column label="评审状态" prop="status">
      <template #default="{ row: item }">
        {{ item.status == 1 ? "评审中" : "评审结束" }}
      </template>
    </el-table-column>
    <el-table-column label="操作">
      <template #default="{ row: item }">
        <el-space>
          <el-button v-if="item.status == 1" type="primary" size="mini" circle @click="showForm(item)">
            <el-tooltip content="填写评审单">
              <i class="el-icon-edit"></i>
            </el-tooltip>
          </el-button>
          <el-popconfirm v-if="item.status == 1" title="确认退回吗!?" @confirm="back(item.id).then((p) => query())">
            <template #reference>
              <el-button :loading="isProcessing" type="danger" size="mini" circle>
                <el-tooltip content="退回">
                  <i class="el-icon-refresh-left"></i>
                </el-tooltip>
              </el-button>
            </template>
          </el-popconfirm>

          <el-button v-else type="primary" size="mini" circle @click="showViewForm(item)">
            <el-tooltip content="查看评审单">
              <i class="el-icon-document"></i>
            </el-tooltip>
          </el-button>
        </el-space>
      </template>
    </el-table-column>
  </el-table>
  <Pager :pager="pager" @change="query"></Pager>
  <Detail :setting="detailSetting" v-if="detailSetting.visible"></Detail>
  <FundAmountReviewForm :setting="fundReviewFormSetting" @change="query" v-if="fundReviewFormSetting.visible">
  </FundAmountReviewForm>

  <FundAmountReviewFormView :setting="fundReviewViewSetting" v-if="fundReviewViewSetting.visible">
  </FundAmountReviewFormView>
  </Base>
</template>

<script lang="ts">
import { DocComponents } from "@/network/officialDoc.ts";
import Detail from "./detail.vue";
import FundAmountReviewForm from "./fundAmountReviewForm.vue";
import FundAmountReviewFormView from "./fundAmountReviewFormView.vue";
import { reactiveShowSetting } from "@/types/showSetting.ts";
import ShowRelBtn from "@/components/showRelBtn.vue";
import { useRouter, useRoute } from "vue-router";
import roles from "@/types/roles.ts";
import Base from "@/views/layout/Base.vue";
import Pager from "@/views/layout/pager.vue";

import { useQueryAmountReview, useAction } from "@/network/projectFundApply.ts";
import {
  defineComponent,
  inject,
  onMounted,
  onUpdated,
  reactive,
  ref,
} from "vue";
import { ElMessageBox } from "element-plus";
export default defineComponent({
  components: {
    ...DocComponents,
    Detail,
    ShowRelBtn,
    FundAmountReviewForm,
    FundAmountReviewFormView,
    Base,
    Pager,
  },
  setup() {
    const fundReviewFormId = ref(null);
    const fundReviewViewId = ref(null);
    const fundReviewViewSetting = reactive({
      id: fundReviewViewId,
      visible: false,
      project_Name: "",
      project_Code: "",
    });
    const fundReviewFormSetting = reactive({
      id: fundReviewFormId,
      visible: false,
      project_Name: "",
      project_Code: "",
    });

    const detailSetting = reactiveShowSetting({ review: false });
    const docShowSetting = reactiveShowSetting();
    const user: any = inject("user");

    const showForm = (item) => {
      fundReviewFormSetting.visible = true;
      fundReviewFormSetting.project_Name = item.project_Name;
      fundReviewFormSetting.project_Code = item.project_Code;
      fundReviewFormId.value = item.id;
    };

    const showViewForm = (item) => {
      fundReviewViewSetting.visible = true;
      fundReviewViewSetting.project_Name = item.project_Name;
      fundReviewViewSetting.project_Code = item.project_Code;
      fundReviewViewId.value = item.id;
    };

    const showDocContent = (id) => {
      docShowSetting.id = id;
      docShowSetting.visible = true;
    };

    // const backConfirm = (id) => {
    //   ElMessageBox.prompt("请填写退回理由", "退回", {
    //     type: "warning",
    //     customClass: "backConfirmForm",
    //     confirmButtonText: "退回",
    //     cancelButtonText: "取消",
    //     inputType: "textarea",
    //     inputPattern: /(.+){5,}/,
    //     inputErrorMessage: "理由不得少于5个字！",
    //     inputPlaceholder: "请输入理由",
    //   }).then(({ value }) => {
    //     run(id, { forceFlag: 1, memo: value });
    //   });
    // };

    const [isLoading, result, query, filter, pager] = useQueryAmountReview();
    const [isProcessing, back] = useAction();
    onMounted(() => {
      query();
    });
    return {
      isProcessing,
      back,
      showDocContent,
      showViewForm,
      query,
      showForm,
      docShowSetting,
      detailSetting,
      isLoading,
      roles,
      user,
      pager,
      data: result,
      filter,
      fundReviewViewSetting,
      fundReviewFormSetting,
      fundReviewFormId,
      fundReviewViewId,
    };
  },
});
</script>

<style lang="scss" >
.backConfirmForm {
  textarea {
    height: 150px;
  }
}
</style>