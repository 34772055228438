
import { ElMessage } from "element-plus";
import { useAmountReview } from "@/network/projectFundApply.ts";
import { defineComponent, toRefs, ref, reactive, watch, onMounted } from "vue";
import settings from "@/settings";
export default defineComponent({
  props: {
    setting: { type: Object },
  },
  emits: ["change"],
  setup(props, { emit }) {
    // const form = reactive({
    //   reduces: [],
    //   attachment: null,
    // });
    const rules = {
      submit_Money: [
        {
          required: true,
          message: "请填写送审金额!",
          trigger: "blur",
        },
      ],
      assessment_Money: [
        {
          required: true,
          message: "请填写评审金额!",
          trigger: "blur",
        },
      ],
      checkSignIn: [
        {
          required: true,
          message: "请填写审核专家签字!",
          trigger: "blur",
        },
      ],
      checkDate: [
        {
          required: true,
          message: "请填写审核专家日期!",
          trigger: "change",
        },
      ],
      attachment: [
        {
          required: true,
          message: "请上传附件！",
          validator: (rule, value, callback) => {
            if (value?.file_Path == null) {
              return callback(Error(rule.message));
            }
            callback();
          },
          trigger: "change",
        },
      ],
    };
    const refForm = ref(null);
    const refUpload = ref(null);

    const { visible, id } = toRefs(props.setting as any);
    const [isLoading, form, find, save, submit] = useAmountReview(id);
    // watch(visible, (v) => {
    //   if (v) {
    //     find().then((p) => {
    //       if (form.attachment) {
    //         fileList.value.push({
    //           url: form.attachment.file_Path,
    //           name: form.attachment.file_Name,
    //         });
    //       }
    //     });
    //   }
    // });

    onMounted(() => {
      find().then((p) => {
        if (form.attachment) {
          fileList.value.push({
            url: form.attachment.file_Path,
            name: form.attachment.file_Name,
          });
        }
      });
    })

    const addReduce = () => {
      form.reduces.push({
        reduceMoney: 0,
        reason: "",
        name: "",
      });
    };
    const fileList = ref([]);

    const removeReduce = (index) => {
      form.reduces.splice(index, 1);
    };

    const doSave = (changeEmit = true) => {
      return new Promise((resolve, reject) => {
        refForm.value.validate((valid) => {
          if (valid) {
            save().then(() => {
              resolve("success");
              if (changeEmit) emit("change");
            });
          } else {
            reject("error");
          }
        });
      });
    };

    const confirm = () => {
      //console.log(visible);
      doSave(false).then(() => {
        submit().then(() => {
          visible.value = false;
          emit("change");
        });
      });
    };

    const onExceed = () => {
      ElMessage.error(
        "仅允许上传一份，请在附件下点击 x 移除现有文件后，重新上传！"
      );
    };
    const onRemoveFile = (file, fileList) => {
      form.attachment = null;
    };
    const onSuccess = (ext) => {
      return (response, file, fileList) => {
        if (response.code == 200) {
          file.url = response.data;
          form.attachment = {
            id: 0,
            file_Path: response.data,
            file_Name: file.name,
          };
        }
      };
    };
    const onPreview = (file) => {
      window.open(file.url);
    };

    return {
      rules,
      fileList,
      refForm,
      isLoading,
      refUpload,
      action: settings.uploadAction,
      onPreview,
      onExceed,
      onRemoveFile,
      onSuccess,
      removeReduce,
      addReduce,
      visible,
      confirm,
      form,
      doSave,
    };
  },
});
