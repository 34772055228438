<template>
  <el-dialog v-model="visible" title="填写评审单">
    <el-card v-loading="isLoading">
      <h1 style="text-align: center">文物保护资金预算评审表</h1>
      <p style="
          margin-bottom: 10px;
          text-align: right;
          border-bottom: 1px dashed black;
          padding-bottom: 10px;
        ">
        项目编号：{{ setting.project_Code }}
      </p>
      <div class="borderForm">
        <el-form :model="form" label-width="200px" ref="refForm" :rules="rules">
          <el-form-item label="项目名称">
            {{ setting.project_Name }}
          </el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item label="送审金额（万元）" prop="submit_Money">
                <el-input v-model="form.submit_Money"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="评审金额（万元）" prop="assessment_Money">
                <el-input v-model="form.assessment_Money"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item>
            <el-table :data="form.reduces" border show-summary>
              <el-table-column label="核减项目、理由和金额" align="center">
                <el-table-column label="核减项目名称">
                  <template #default="{ row: item }">
                    <el-input v-model="item.name" placeholder=""></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="核减理由">
                  <template #default="{ row: item }">
                    <el-input v-model="item.reason" placeholder=""></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="核减金额（万元）" prop="reduceMoney" sortable>
                  <template #default="{ row: item }">
                    <el-input v-model="item.reduceMoney" placeholder=""></el-input>
                  </template>
                </el-table-column>
                <el-table-column width="50" align="center">
                  <template #header>
                    <el-button type="text" icon="el-icon-plus" @click="addReduce"></el-button>
                  </template>
                  <template #default="{ $index }">
                    <el-button type="text" icon="el-icon-remove" @click="removeReduce($index)"></el-button>
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table>
          </el-form-item>

          <el-form-item label="其他说明及建议">
            <el-input v-model="form.otherAdvise" type="textarea"></el-input>
          </el-form-item>

          <el-form-item label="审定专家意见"><el-input v-model="form.expertAdvise" type="textarea"></el-input></el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item label="审定专家签字"><el-input v-model="form.expertSignIn"></el-input></el-form-item></el-col>
            <el-col :span="12">
              <el-form-item label="审定日期"><el-date-picker v-model="form.expertSignInDate"></el-date-picker></el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="审核专家签字" prop="checkSignIn"><el-input v-model="form.checkSignIn"
                  prop="checkSignIn"></el-input></el-form-item></el-col>
            <el-col :span="12">
              <el-form-item label="审核日期" prop="checkDate"><el-date-picker v-model="form.checkDate"
                  prop="checkDate"></el-date-picker></el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="附件" prop="attachment">
            <el-upload ref="refUpload" :limit="1" :on-preview="onPreview" :on-exceed="onExceed" :on-remove="onRemoveFile"
              :action="action" multiple :on-success="onSuccess({})" accept="application/pdf" :file-list="fileList">
              <el-button type="primary">点击上传</el-button>
              <template #tip>
                <div class="el-upload__tip">请上传pdf文件</div>
              </template>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="visible = false">关闭</el-button>
        <el-button type="success" @click="doSave">保存</el-button>
        <el-popconfirm title="确认提交吗！？" @confirm="confirm()" placement="top">
          <template #reference>
            <el-button type="primary">提交</el-button>
          </template>
        </el-popconfirm>
      </span>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import { ElMessage } from "element-plus";
import { useAmountReview } from "@/network/projectFundApply.ts";
import { defineComponent, toRefs, ref, reactive, watch, onMounted } from "vue";
import settings from "@/settings";
export default defineComponent({
  props: {
    setting: { type: Object },
  },
  emits: ["change"],
  setup(props, { emit }) {
    // const form = reactive({
    //   reduces: [],
    //   attachment: null,
    // });
    const rules = {
      submit_Money: [
        {
          required: true,
          message: "请填写送审金额!",
          trigger: "blur",
        },
      ],
      assessment_Money: [
        {
          required: true,
          message: "请填写评审金额!",
          trigger: "blur",
        },
      ],
      checkSignIn: [
        {
          required: true,
          message: "请填写审核专家签字!",
          trigger: "blur",
        },
      ],
      checkDate: [
        {
          required: true,
          message: "请填写审核专家日期!",
          trigger: "change",
        },
      ],
      attachment: [
        {
          required: true,
          message: "请上传附件！",
          validator: (rule, value, callback) => {
            if (value?.file_Path == null) {
              return callback(Error(rule.message));
            }
            callback();
          },
          trigger: "change",
        },
      ],
    };
    const refForm = ref(null);
    const refUpload = ref(null);

    const { visible, id } = toRefs(props.setting as any);
    const [isLoading, form, find, save, submit] = useAmountReview(id);
    // watch(visible, (v) => {
    //   if (v) {
    //     find().then((p) => {
    //       if (form.attachment) {
    //         fileList.value.push({
    //           url: form.attachment.file_Path,
    //           name: form.attachment.file_Name,
    //         });
    //       }
    //     });
    //   }
    // });

    onMounted(() => {
      find().then((p) => {
        if (form.attachment) {
          fileList.value.push({
            url: form.attachment.file_Path,
            name: form.attachment.file_Name,
          });
        }
      });
    })

    const addReduce = () => {
      form.reduces.push({
        reduceMoney: 0,
        reason: "",
        name: "",
      });
    };
    const fileList = ref([]);

    const removeReduce = (index) => {
      form.reduces.splice(index, 1);
    };

    const doSave = (changeEmit = true) => {
      return new Promise((resolve, reject) => {
        refForm.value.validate((valid) => {
          if (valid) {
            save().then(() => {
              resolve("success");
              if (changeEmit) emit("change");
            });
          } else {
            reject("error");
          }
        });
      });
    };

    const confirm = () => {
      //console.log(visible);
      doSave(false).then(() => {
        submit().then(() => {
          visible.value = false;
          emit("change");
        });
      });
    };

    const onExceed = () => {
      ElMessage.error(
        "仅允许上传一份，请在附件下点击 x 移除现有文件后，重新上传！"
      );
    };
    const onRemoveFile = (file, fileList) => {
      form.attachment = null;
    };
    const onSuccess = (ext) => {
      return (response, file, fileList) => {
        if (response.code == 200) {
          file.url = response.data;
          form.attachment = {
            id: 0,
            file_Path: response.data,
            file_Name: file.name,
          };
        }
      };
    };
    const onPreview = (file) => {
      window.open(file.url);
    };

    return {
      rules,
      fileList,
      refForm,
      isLoading,
      refUpload,
      action: settings.uploadAction,
      onPreview,
      onExceed,
      onRemoveFile,
      onSuccess,
      removeReduce,
      addReduce,
      visible,
      confirm,
      form,
      doSave,
    };
  },
});
</script>

<style scoped></style>